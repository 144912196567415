import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./client.css";

const clientLogos = [
	{
		logo: "https://cdn.easyfrontend.com/pictures/logos/color-logo-1.png",
		alt: "",
	},
	{
		logo: "https://cdn.easyfrontend.com/pictures/logos/color-logo-2.png",
		alt: "",
	},
	{
		logo: "https://cdn.easyfrontend.com/pictures/logos/color-logo-3.png",
		alt: "",
	},
	{
		logo: "https://cdn.easyfrontend.com/pictures/logos/color-logo-4.png",
		alt: "",
	},
	{
		logo: "https://cdn.easyfrontend.com/pictures/logos/color-logo-5.png",
		alt: "",
	},
	{
		logo: "https://cdn.easyfrontend.com/pictures/logos/color-logo-6.png",
		alt: "",
	},
	{
		logo: "https://cdn.easyfrontend.com/pictures/logos/color-logo-7.png",
		alt: "",
	},
	{
		logo: "https://cdn.easyfrontend.com/pictures/logos/color-logo-8.png",
		alt: "",
	},
	{
		logo: "https://cdn.easyfrontend.com/pictures/logos/color-logo-9.png",
		alt: "",
	},
];

const Client = () => {
	return (
		<section className="ezy__clients9_ezTulFx3" id="our-clients">
			<Container>
				<Row className="align-items-md-end text-center">
					<Col md={9} className="mb-4 mb-sm-5 text-start">
						<Row>
							<Col xl={9}>
								<h2 className="ezy__clients9_ezTulFx3-heading mb-4">
									Our Trusted Clients
								</h2>
								<p className="ezy__clients9_ezTulFx3-sub-heading mb-0">
									It’s easier to reach your savings goals when you have the
									right savings account. Take a look and find the right one for
									you!It’s easier to reach your savings goals when you have the
									right savings account.
								</p>
							</Col>
						</Row>
					</Col>
					{clientLogos.map((client, i) => (
						<Col sm={6} md={4} xl={3} className="mt-3 mt-sm-4" key={i}>
							<div className="ezy__clients9_ezTulFx3-item p-3 p-lg-5">
								<img
									src={client.logo}
									alt={client.alt}
									className="ezy__clients9_ezTulFx3-img img-fluid"
								/>
							</div>
						</Col>
					))}
				</Row>
			</Container>
		</section>
	);
};

export default Client;

