import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCannabis } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import "./services.css";

const services = [
	{
		icon: faCannabis,
		title: "Product Design",
		text: "Assumenda non repellendus distinctio nihil dicta sapiente, quibusdam maiores, illum at, aliquid blanditiis eligendi qui.",
	},
	{
		icon: faCannabis,
		title: "Photography",
		text: "Assumenda non repellendus distinctio nihil dicta sapiente, quibusdam maiores, illum at, aliquid blanditiis eligendi qui.",
	},
	{
		icon: faCannabis,
		title: "Coding",
		text: "Assumenda non repellendus distinctio nihil dicta sapiente, quibusdam maiores, illum at, aliquid blanditiis eligendi qui.",
	},
	{
		icon: faCannabis,
		title: "Content Writing",
		text: "Assumenda non repellendus distinctio nihil dicta sapiente, quibusdam maiores, illum at, aliquid blanditiis eligendi qui.",
	},
	{
		icon: faCannabis,
		title: "Branding",
		text: "Assumenda non repellendus distinctio nihil dicta sapiente, quibusdam maiores, illum at, aliquid blanditiis eligendi qui.",
	},
	{
		icon: faCannabis,
		title: "Data Entry",
		text: "Assumenda non repellendus distinctio nihil dicta sapiente, quibusdam maiores, illum at, aliquid blanditiis eligendi qui.",
	},
];

const ServiceItem = ({ service }) => (
	<Card className="ezy__service23_OGANd7P3-card p-4 border-0 rounded-0">
		<Card.Body className="pt-4">
			<div className="ezy__service23_OGANd7P3-card-icon">
				<FontAwesomeIcon icon={service.icon} />
			</div>
		</Card.Body>
		<Card.Body className="pb-4">
			<h5 className="ezy__service23_OGANd7P3-card-title mb-3">{service.title}</h5>
			<p className="ezy__service23_OGANd7P3-card-sub-title mb-0">{service.text}</p>
		</Card.Body>
	</Card>
);

ServiceItem.propTypes = {
	service: PropTypes.object.isRequired,
};

const Services = () => {
	return (
		<section className="ezy__service23_OGANd7P3" id="our-services">
			<Container>
				<Row className="ezy__service23_OGANd7P3-separator">
					<Col xs={12} className="mb-5 text-center">
						<Row className="justify-content-center">
							<Col xs={12} md={10} lg={5}>
								<h3 className="ezy__service23_OGANd7P3-heading">Our Services</h3>
								<p className="ezy__service23_OGANd7P3-sub-heading mt-4 px-md-4">
									Assumenda non repellendus distinctio nihil dicta sapiente,
									quibusdam maiores, illum at, aliquid blanditiis eligendi qui.
								</p>
							</Col>
						</Row>
					</Col>

					<Row className="ezy__service23_OGANd7P3-row">
						{services.map((service, i) => (
							<Col xs={12} md={6} xl={4} className="mt-4" key={i}>
								<ServiceItem service={service} />
							</Col>
						))}
					</Row>
				</Row>
			</Container>
		</section>
	);
};

export default Services;

