// src/components/ScrollToTopButton.js
import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { FaArrowUp } from 'react-icons/fa'; // Ensure you have react-icons installed
import './ScrollToTop.css'

const ScrollToTopButton = () => {
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 300) { // Show button when scrolled more than 300px
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        showButton && (
            <Button
                variant="primary"
                className="scroll-to-top"
                onClick={scrollToTop}
            >
                <FaArrowUp />
            </Button>
        )
    );
};

export default ScrollToTopButton;
