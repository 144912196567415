import React, {Fragment} from 'react';

import Navigation from '../components/navbar';
import Header from '../components/header';
import AboutUs from '../components/aboutUs'
import Services from '../components/services';
import Client from '../components/client';
import ContactUs from '../components/contactUs';
import Footer from '../components/footer';
import ScrollToTopButton from '../components/ScrollToTopButton';

const Index = () => {
    return (
        <Fragment>

            <Navigation />
			<Header />
			<AboutUs />
			<Services />
			<Client/>
			<ContactUs />
			<Footer />
			<ScrollToTopButton />

        </Fragment>
    );
}

export default Index;
